import React from 'react'
import '../../scss/pages/profiles.scss'

import { siteMetadata } from '../../../gatsby-config'
import ProfileLayout from 'components/layout/profiles'
import Meta from 'components/meta'
import peopleData from '../../../content/people.json';

const person = peopleData.people[2];

const Weinstein = ({ data, location }) => (
  <ProfileLayout location={location} profile_name="Seth P. Weinstein" profile_key="weinstein">
      <Meta site={siteMetadata} title="Seth P. Weinstein" />
	  	<div className="row">
			<div className="col-md-9 pr-md-5">
				<h4>About</h4>

				<p>Seth Weinstein has an advocacy practice focused on criminal and regulatory matters, appearing before all levels of court in Ontario and the Supreme Court of Canada. He represents individual and corporate clients on a wide variety of criminal charges, including homicides, sexual assault, fraud, drinking and driving offences and offences under the <i>Controlled Drugs and Substances Act</i>. He has also served as a per diem Crown Attorney.</p>

				<p>Seth also represents individuals in professional discipline matters and assists individuals and corporations being investigated or charged under a variety of regulatory statutes, including the <i>Occupational Health and Safety Act</i> and <i>Environmental Protection Act</i>.</p>

				<p>Seth has extensive international expertise in extradition and mutual legal assistance matters and has been counsel in some of the leading cases in that area. He is also the co-author of <i>Prosecuting and Defending Extradition Cases: A Practitioner’s Handbook</i>, Emond, 2017.</p>

				<p>In addition to his practice, Seth is an adjunct professor at Osgoode Hall Law School where he teaches the Law of Evidence. He is also an instructor in the Osgoode Hall trial advocacy course. Seth served as a Director of the Criminal Lawyers’ Association for four years. Prior to being elected Director, he served for seven years as co-editor of <i>For the Defence</i>, the publication for the Criminal Lawyers’ Association. Seth is an active member of the Canadian Bar Association, the Advocates Society and the Criminal Lawyers’ Association. Since 2018, Seth has been recognized in <i>Best Lawyers in Canada ©</i> for his work in the area of criminal defence.</p>

				<p>Seth has chaired or been a panelist at multiple continuing legal education programs on a wide variety of criminal law related topics. He is the author of a number of papers on criminal law issues as well as co-author of “Corporate Criminal Liability: Principals and Principles” in <i>The Controlling Mind – Exercising Legal Control: Its Obligations and Liabilities</i>, ed. Barry Lipson, Carswell, 2012.</p>

				<p>Seth received his B.A. from the University of Western Ontario in 1993 and his LL.B. from Osgoode Hall Law School in 1998. He was the recipient of the Stephen Leggett Award for the highest standing in the Criminal Law Intensive program.</p>

			</div>
			<div className="col-md-3 contact-card">
				<h4 className="text-primary">Get in touch</h4>
				<p className="text-primary">15 Bedford Rd.<br/>
				Toronto, Ontario <br/>
				M5R 2J7</p>

				<p><a className="d-flex align-items-center" href={"tel:"+person.phone_code}><img className="icon pr-1" src="/img/icons/blue-phone.png"/>{person.phone}</a></p>
				<p><a className="d-flex align-items-center" href={"mailto:"+person.email}><img className="icon pr-1" src="/img/icons/blue-email.png"/>{person.email}</a></p>
				<p><a className="d-flex align-items-center" href={"mailto:"+person.assistant_contact}><img className="icon" src="/img/icons/blue-whatsapp.png"/>Contact {person.short_name}’s Assistant</a></p>
				{/* <p><a className="d-flex align-items-center" ><img className="icon" src="/img/icons/blue-twitter.png"/>Follow {person.short_name} on Twitter</a></p> */}

			</div>
		</div>	  
  </ProfileLayout>
)
export default Weinstein
